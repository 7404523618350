<template>
  <div v-if="dialog && users">
    <v-dialog
      :value="dialog"
      max-width="65%"
      persistent
      transition="dialog-bottom-transition"
      style="z-index: 9999 !important"
    >
      <v-card>
        <!-- <v-toolbar>
          <v-btn icon @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar> -->
        <div class="modal-header">
          <h5 class="modal-title" id="formModal">
            {{
              this.reportUrl == "master"
                ? "List Senior Statement"
                : this.reportUrl == "agent"
                ? "List Master Statement"
                : this.reportUrl == "player"
                ? "List Agent Statement"
                : this.reportUrl == "playerDetail"
                ? "List Player Statement"
                : "Loading ..."
            }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <v-card-text>
          <br />
          <!-- <v-row class="pl-5">
            <v-col cols="12" sm="2" md="6" class="text-left my-4"> </v-col>
            <v-col cols="6" sm="6" md="4" class="text-right">
              <v-text-field
                v-model="v_search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="mr-5"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="6" md="2" class="text-right">
              <v-btn
                class="white--text btn-custom mr-4"
                color="green darken-1"
                depressed
                @click="search"
              >
                SEARCH
              </v-btn>
            </v-col>
          </v-row> -->
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-search"></i>
              </div>
            </div>
            <input v-model="v_search" type="text" class="form-control mr-1" />
            <button
              class="btn btn-primary"
              color="green darken-1"
              depressed
              @click="search"
            >
              SEARCH
            </button>
          </div>
          <br />
          <div class="card">
            <div class="card-body">
              <div class="table-responsive" style="text-align: center">
                <v-data-table
                  style="font-family: 'khmer mef1'"
                  class=" table table-striped"
                  id="table-1"
                  item-key="_id._id"
                  :headers="headers"
                  :footer-props="footerProps"
                  :items="users ? users.object : []"
                  :server-items-length="users ? users.objectCount.count : null"
                  :sort-desc.sync="dataRequest.descendingFormat"
                  hide-default-header
                  :sort-by.sync="dataRequest.sortByFormat"
                  :page.sync="dataRequest.page"
                  :items-per-page.sync="dataRequest.rowsPerPage"
                  @update:page="$emit('getData1')"
                  @update:items-per-page="$emit('getData1')"
                  @update:sort-desc="$emit('getData1')"
                  mobile-breakpoint="0"
                >
                  <template v-slot:header="{ props }">
                    <th v-for="head in props.headers" :key="head._id">
                      {{ head.text }}
                    </th>
                  </template>
                  <template v-slot:[`item`]="{ item }">
                    <tr>
                      <td>{{ users.object.indexOf(item) + 1 }}</td>

                      <td>
                        <v-icon
                          v-if="
                            user.role_name == 'MASTER-RP' ||
                            user.role_name == 'SUB-MASTER-RP'
                          "
                          color="green"
                          small
                          class=""
                          @click="getUserChild5(item)"
                          >mdi-eye
                        </v-icon>
                        <v-icon
                          v-if="
                            user.role_name !== 'MASTER-RP' &&
                            user.role_name !== 'SUB-MASTER-RP'
                          "
                          color="green"
                          small
                          class=""
                          @click="getUserChild2(item)"
                          >{{ reportUrl ? "mdi-eye" : "mdi-eye-off" }}
                        </v-icon>
                      </td>
                      <td>{{ item._id.app_id }}</td>
                      <td v-if="item._id.com">
                        {{
                          item._id.com &&
                          (user.role_name == "DEVELOPER" ||
                            user.role_name == "MANAGER" ||
                            user.role_name == "COMPANY" ||
                            user.role_name == "SUB-COMPANY")
                            ? item._id.com
                            : "---"
                        }}
                      </td>
                      <td v-if="item._id.admin">
                        {{
                          item._id.admin &&
                          (user.role_name == "DEVELOPER" ||
                            user.role_name == "MANAGER" ||
                            user.role_name == "COMPANY" ||
                            user.role_name == "SUB-COMPANY" ||
                            user.role_name == "SUB-SENIOR-RP" ||
                            user.role_name == "SENIOR-RP" )
                            ? item._id.admin
                            : "---"
                        }}
                      </td>
                      <td v-if="item._id.master">
                        {{
                          item._id.master &&
                          (user.role_name == "DEVELOPER" ||
                            user.role_name == "MANAGER" ||
                            user.role_name == "COMPANY" ||
                            user.role_name == "SUB-COMPANY" ||
                            user.role_name == "SUB-SENIOR-RP" ||
                            user.role_name == "SENIOR-RP" ||
                            user.role_name == "SUB-MASTER-RP" ||
                            user.role_name == "MASTER-RP")
                            ? item._id.master
                            : "---"
                        }}
                      </td>
                      <td v-if="item._id.agent">{{ item._id.agent }}</td>
                      <td v-if="item._id.player">{{ item._id.player }}</td>
                      <td v-if="reportUrl">
                        {{
                          reportUrl == "master"
                            ? currencyFormat(item._id.admin_balance)
                            : reportUrl == "agent"
                            ? currencyFormat(item._id.master_balance)
                            : reportUrl == "player"
                            ? currencyFormat(item._id.agent_balance)
                            : reportUrl == "playerDetail"
                            ? currencyFormat(item._id.player_balance)
                            : "Loading ..."
                        }}
                      </td>
                      <td class="amount-bet-color">
                        {{ currencyFormat(item.amount) }}
                      </td>
                      <td
                        :class="
                          item.amount_win < 0 ? 'lose-color' : 'win-color'
                        "
                      >
                        {{ currencyFormat(item.amount_win) }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      v-if="
                        user.role_name == 'MASTER-RP' ||
                        user.role_name == 'SUB-MASTER-RP'
                      "
                      color="green"
                      small
                      class=""
                      @click="getUserChild5(item)"
                      >mdi-eye
                    </v-icon>
                    <v-icon
                      v-if="
                        user.role_name != 'MASTER-RP' &&
                        user.role_name != 'SUB-MASTER-RP'
                      "
                      color="green"
                      small
                      class=""
                      @click="getUserChild2(item)"
                      >{{ reportUrl ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                  </template>
                  <template slot="body.append">
                    <td
                      :colspan="headers ? headers.length - 2 : 2"
                      class="text-xs-center pl-4 py-2"
                    >
                      {{ $t("title.total") }}
                    </td>
                    <td class="text-xs-center total-style pl-4 py-2">
                      {{
                        users.objectCount.amount
                          ? currencyFormat(users.objectCount.amount)
                          : 0
                      }}
                    </td>
                    <td
                      class="
                        text-xs-center
                        pl-4
                        total-style
                        font-weight-bold
                        py-2
                      "
                      :style="
                        users.objectCount.amount_win < 0
                          ? 'color:#BA2D4A'
                          : 'color:#39afd6'
                      "
                    >
                      {{
                        users.objectCount.amount_win
                          ? currencyFormat(users.objectCount.amount_win)
                          : 0
                      }}
                    </td>
                  </template>
                </v-data-table>
              </div>
              <!-- <div class="text-center pt-2">
                <v-pagination
                  v-model="data2.page"
                  :length="
                    users
                      ? users.objectCount.count % data2.rowsPerPage != 0
                        ? users.objectCount.count / data2.rowsPerPage + 1
                        : users.objectCount.count / data2.rowsPerPage
                      : 0
                  "
                ></v-pagination>
                <v-text-field
                  :value="data2.rowsPerPage"
                  label="Items per page"
                  type="number"
                  min="-1"
                  max="15"
                  @input="data2.rowsPerPage = parseInt($event, 10)"
                ></v-text-field>
              </div> -->
              
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <statement-list2
      v-if="user.role_name != 'MASTER-RP' && user.role_name !== 'SUB-MASTER-RP'"
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild2"
      :data-request="data2"
      :user="user"
      v-on:getData2="getUserChild2(item)"
      @onSearch="onSearch"
      :date="date"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
    <statement-list5
      v-if="user.role_name == 'MASTER-RP' || user.role_name == 'SUB-MASTER-RP'"
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild5"
      :data-request="data5"
      v-on:getData5="getUserChild5(item)"
      :date="date"
      @onSearch="onSearch"
      :user="user"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
    <loader
      v-if="this.loaderD1 == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
  </div>
</template>

<script>
// import headerDev from "../../_api/headerDev";
// import headerCompany from "../../_api/headerCompany";
import headerSenior from "../../_api/headerSenior";
import headerMaster from "../../_api/headerMaster";
import headerAgent from "../../_api/headerAgent";
import headerPlayer from "../../_api/headerPlayer";
import StatementList2 from "./Dialog2";
import StatementList5 from "./Dialog5";
import headerPlayerDetail from "../../_api/headerPlayerDetail";
import moment from "moment";
import Vue from "vue";

export default {
  components: { StatementList2, StatementList5 },
  props: ["darkmode", "dialog", "users", "dataRequest", "user", "date"],
  data: () => {
    return {
      dialogListUser: false,
      userChild2: [],
      userChild5: [],
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      item: null,
      data2: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["amount_win"],
        sortBy: "amount_win",
        page: 1,
        rowsPerPage: 10,
        fields: [],
        search: "",
        type: "viewer",
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        statusTime: false,
      },
      data5: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id._id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        channelType: 0,
        fields: [],
        search: "",
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
      v_startDate: false,
      v_endDate: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_search: "",
      params: "",
      loaderD1: false,
    };
  },
  computed: {
    headers: function () {
      if (
        this.user.role_name == "DEVELOPER" ||
        this.user.role_name == "MANAGER" ||
        this.user.role_name == "SPECIAL-MANAGER"
      ) {
        return headerSenior;
      }
      if (
        this.user.role_name == "COMPANY" ||
        this.user.role_name == "SUB-COMPANY"
      ) {
        return headerMaster;
      } else if (
        this.user.role_name == "SENIOR-RP" ||
        this.user.role_name == "SUB-SENIOR-RP"
      )
        return headerAgent;
      else if (
        this.user.role_name == "MASTER-RP" ||
        this.user.role_name == "SUB-MASTER-RP"
      )
        return headerPlayer;
      else if (
        this.user.role_name == "AGENT-RP" ||
        this.user.role_name == "SUB-AGENT-RP"
      )
        return headerPlayerDetail;
      else return [];
    },
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "master";
        else if (
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY"
        )
          return "agent";
        else if (
          this.user.role_name == "SENIOR-RP" ||
          this.user.role_name == "SUB-SENIOR-RP"
        )
          return "player";
        else if (
          this.user.role_name == "MASTER-RP" ||
          this.user.role_name == "SUB-MASTER-RP"
        )
          return "playerDetail";
        else return;
      } else return null;
    },
  },

  methods: {
    getUserChild2(item, search = "") {
      this.loaderD1 = true;
      if (search) this.data2.search = search;
      else this.data2.search = "";
      if (this.date) {
        this.data2.startDate =
          moment(this.date.startDate).format("YYYY-MM-DD") + "T00:00:00";
        this.data2.endDate =
          moment(this.date.endDate).format("YYYY-MM-DD") + "T23:59:59";
        this.data2.statusTime = this.date.statusTime;
      }
      this.item = item;
      this.data2.fields = this.calFields();
      this.data2.sortByFormat.length < 1
        ? (this.data2.sortBy = "_id")
        : (this.data2.sortBy = this.data2.sortByFormat[0]);
      this.data2.descendingFormat.length > 0
        ? (this.data2.descending = this.data2.descendingFormat[0])
        : (this.data2.descending = true);
      Vue.$cookies.set("currency-type", 5, 0);
      this.$request
        .post({
          url: "statementCurrency/" + this.reportUrl + "/" + this.calID(item),
          data: this.data2,
        })
        .then((res) => {
          if (res.data.code) {
            this.loaderD1 = false;
            this.userChild2 = res.data.data;
            this.dialogListUser = true;
          }
        });
    },
    calID(item) {
      if (
        this.user.role_name == "DEVELOPER" ||
        this.user.role_name == "MANAGER" ||
        this.user.role_name == "SPECIAL-MANAGER"
      ) {
        return item._id.admin_id;
      }
      if (
        this.user.role_name == "COMPANY" ||
        this.user.role_name == "SUB-COMPANY"
      ) {
        return item._id.master_id;
      } else if (
        this.user.role_name == "SENIOR-RP" ||
        this.user.role_name == "SUB-SENIOR-RP"
      )
        return item._id.agent_id;
      else if (
        this.user.role_name == "MASTER-RP" ||
        this.user.role_name == "SUB-MASTER-RP"
      )
        return item._id.player_id;
      // else if (this.user.role_name == "AGENT-D" || this.user.role_name == "SUB-AGENT-D") return headerPlayer;
      else return this.user.role_name;
    },
    calFields() {
      if (
        this.user.role_name == "DEVELOPER" ||
        this.user.role_name == "MANAGER" ||
        this.user.role_name == "SPECIAL-MANAGER"
      ) {
        return ["_id.com", "_id.app_id", "amount", "amount_win", "amount_lose"];
      } else if (
        this.user.role_name == "COMPANY" ||
        this.user.role_name == "SUB-COMPANY"
      ) {
        return ["_id.com", "_id.app_id", "amount", "amount_win", "amount_lose"];
      } else if (
        this.user.role_name == "SENIOR-RP" ||
        this.user.role_name == "SUB-SENIOR-RP"
      )
        return ["_id.com", "_id.app_id", "amount", "amount_win", "amount_lose"];
      else if (
        this.user.role_name == "MASTER-RP" ||
        this.user.role_name == "SUB-MASTER-RP"
      )
        return ["_id.com", "_id.app_id", "amount", "amount_win", "amount_lose"];
      else return this.user.role_name;
    },
    calTitle() {
      if (
        this.user.role_name == "DEVELOPER" ||
        this.user.role_name == "MANAGER" ||
        this.user.role_name == "SPECIAL-MANAGER"
      ) {
        return this.user.user_name;
      } else if (
        this.user.role_name == "COMPANY" ||
        this.user.role_name == "SUB-COMPANY"
      ) {
        return this.user.user_name;
      } else if (
        this.user.role_name == "SENIOR-RP" ||
        this.user.role_name == "SUB-SENIOR-RP"
      )
        return this.user.user_name;
      else if (
        this.user.role_name == "MASTER-RP" ||
        this.user.role_name == "SUB-MASTER-RP"
      )
        return this.user.user_name;
      else return this.user.role_name;
    },
    getUserChild5(item, search = "") {
      this.loaderD1 = true;
      if (search) this.data5.search = search;
      else this.data5.search = "";
      if (this.date) {
        this.data5.startDate = this.date.startDate;
        this.data5.endDate = this.date.endDate;
      }
      this.item = item;
      this.data5.fields = this.calFields();
      this.data5.sortByFormat.length < 1
        ? (this.data5.sortBy = "_id")
        : (this.data5.sortBy = this.data5.sortByFormat[0]);
      this.data5.descendingFormat.length > 0
        ? (this.data5.descending = this.data5.descendingFormat[0])
        : (this.data5.descending = true);
      Vue.$cookies.set("currency-type", 5, 0);
      this.$request
        .post({
          url: "statementCurrency/" + this.reportUrl + "/" + this.calID(item),
          data: this.data5,
        })
        .then((res) => {
          if (res.data.code) {
            this.loaderD1 = false;
            this.dialogListUser = true;
            this.userChild5 = res.data.data;
          }
        });
    },
    onSearch(search) {
      if (
        this.user.role_name == "MASTER-RP" ||
        this.user.role_name == "SUB-MASTER-RP"
      )
        this.getUserChild5(this.item, search);
      else this.getUserChild2(this.item, search);
    },
    search() {
      // if(this.user.role_name=="MASTER") this.$emit('getData5', this.v_search)
      this.$emit("getData1", this.v_search);
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
.btn-custom {
  position: relative;
  top: 10px;
}

.amount-bet-color {
  color: goldenrod !important;
}

.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}

.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
</style>
