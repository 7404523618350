<template>
  <div v-if="dialog && users">
    <v-dialog
      :value="dialog"
      max-width="65%"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="modal-header">
          <h5 class="modal-title" id="formModal">Detail Player Statement</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <v-card-text>
          <br />
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="input-group">
                <div class="col-md-3 col-sm-12">
                  <!-- <v-select
                    v-model="defaultSelected"
                    :items="selectChannel"
                    item-text="name"
                    item-value="id"
                    solo
                  ></v-select> -->
                  <div class="form-group">
                    <select class="form-control form-control-sm" v-model="channel_type">
                      <option :value="null" disabled selected>Choose Channel</option>
                      <option
                        v-for="option in selectChannel"
                        v-bind:key="option.id"
                        v-bind:value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-9 col-sm-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="fas fa-search"></i>
                      </div>
                    </div>
                    <input
                      v-model="v_search"
                      type="text"
                      class="form-control mr-1"
                    />
                    <button class="btn btn-primary" @click="search">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-search"></i>
              </div>
            </div>
            <input v-model="v_search" type="text" class="form-control mr-1" />
            <button
              class="btn btn-primary"
              color="green darken-1"
              depressed
              @click="search"
            >
              SEARCH
            </button>
          </div> -->
          <div class="card">
            <div class="card-body">
              <div class="table-responsive" style="text-align: center;">
                <v-data-table
                  style="font-family: 'khmer mef1'"
                  class=" table table-striped"
                  id="table-1"
                  item-key="_id._id"
                  :headers="headers"
                  :footer-props="footerProps"
                  :items="users ? users.object : []"
                  :server-items-length="
                    users ? parseInt(users.objectCount.count) : null
                  "
                  :sort-desc.sync="dataRequest.descendingFormat"
                  :sort-by.sync="dataRequest.sortByFormat"
                  hide-default-header
                  :page.sync="dataRequest.page"
                  :items-per-page.sync="dataRequest.rowsPerPage"
                  @update:page="updatePage"
                  @update:items-per-page="updatePage"
                  @update:sort-desc="updatePage"
                  :loading="getLoading"
                  mobile-breakpoint="0"
                >
                  <template v-slot:header="{ props }">
                    <th v-for="head in props.headers" :key="head._id">
                      {{ head.text }}
                    </th>
                  </template>
                  <template v-slot:[`item`]="{ item }">
                    <tr>
                      <!-- <td>{{ users.object.indexOf(item) + 1 }}</td> -->
                      <td>{{ item.app_id }}</td>
                      <td>{{ item.public_ip }}</td>
                      <td>{{ item.date }}</td>
                      <td>{{ item.fight_no }}</td>
                      <td>
                        <span v-if="item.channel_type == 90"> Lotto (SLOT)</span>
                        <span v-else-if="item.channel_type == 91"> Yuki (SLOT)</span>
                        <span v-else-if="item.channel_type == 92"> PP78 (SLOT)</span>
                        <span v-else-if="item.channel_type == 1"> CO1 </span>
                        <span v-else-if="item.channel_type == 2"> CO2 </span>
                        <span v-else-if="item.channel_type == 3"> CO3 </span>
                        <span v-else-if="item.channel_type == 4"> CO4 </span>
                        <span v-else-if="item.channel_type == 15"> CO5 </span>
                        <span v-else-if="item.channel_type == 16"> CO6 </span>
                        <span v-else-if="item.channel_type == 5"> KL1 </span>
                        <span v-else-if="item.channel_type == 6"> BA1 </span>
                        <span v-else-if="item.channel_type == 7"> BA2 </span>
                        <span v-else-if="item.channel_type == 18"> BA3 </span>
                        <span v-else-if="item.channel_type == 8"> TD1 </span>
                        <span v-else-if="item.channel_type == 9"> TD2 </span>
                        <span v-else-if="item.channel_type == 19"> TD3 </span>
                        <span v-else-if="item.channel_type == 10"> LO1 </span>
                        <span v-else-if="item.channel_type == 11"> YU1 </span>
                        <span v-else-if="item.channel_type == 12"> AP1 </span>
                        <span v-else-if="item.channel_type == 17"> AP2 </span>
                        <span v-else-if="item.channel_type == 13"> FT1 </span>
                        <span v-else-if="item.channel_type == 14"> TS1 </span>
                        <span v-else-if="item.channel_type == 20"> HAN2 </span>
                        <span v-else-if="item.channel_type == 21"> HAN3 </span>
                        <span v-else-if="item.channel_type == 93"> Klaklouk (SLOT) </span>
                        <span v-else-if="item.channel_type == 94"> Dragon (SLOT) </span>
                        <span v-else-if="item.channel_type == 95"> Baccarat (SLOT) </span>
                        <span v-else-if="item.channel_type == 96"> Doden (SLOT)</span>
                        <span v-else-if="item.channel_type == 97"> Sicbo (SLOT)</span>
                        <span v-else-if="item.channel_type == 98"> Taixiu (SLOT)</span>
                      </td>
                      <td :class="item.type_of_betting.color">
                        {{ item.type_of_betting.name }}
                      </td>
                      <td>
                        <span :class="item.result1.color" v-if="item.result1">
                          {{ item.result1.name }} {{ item.result1.number_label }} 
                        </span>
                        <span :class="item.result2.color" v-if="item.result2">
                          | {{ item.result2.name }} {{ item.result2.number_label }}
                        </span>
                        <span :class="item.result3.color" v-if="item.result3">
                          | {{ item.result3.name }} {{ item.result3.number_label }}
                        </span>
                        <span :class="item.result4.color" v-if="item.result4">
                          | {{ item.result4.name }} {{ item.result4.number_label }}
                        </span>
                        <span :class="item.result5.color" v-if="item.result5">
                          | {{ item.result5.name }} {{ item.result5.number_label }}
                        </span>
                      </td>
                      <td class="amount-bet-color">
                        {{ currencyFormat(item.amount) }}
                      </td>
                      <td
                        class="font-weight-bold"
                        :style="
                          item.amount_win < 0
                            ? 'color:#BA2D4A; '
                            : 'color:#39afd6'
                        "
                      >
                        {{ currencyFormat(item.amount_win) }}
                      </td>
                      <td class="font-weight-bold">
                        {{ currencyFormat(item.cast) }}
                      </td>
                    </tr>
                  </template>
                  <template slot="body.append">
                    <td :colspan="7">
                      {{ $t("title.total") }}
                    </td>
                    <td
                      class="total-style font-weight-bold"
                      :style="
                        users.objectCount.amount_win < 0
                          ? 'color:#BA2D4A'
                          : 'color:#39afd6'
                      "
                    >
                      {{
                        users.objectCount.amount
                          ? currencyFormat(users.objectCount.amount)
                          : 0
                      }}
                    </td>
                    <td
                      class="
                        total-style
                        font-weight-bold
                      "
                      :style="
                        users.objectCount.amount_win < 0
                          ? 'color:#BA2D4A'
                          : 'color:#39afd6'
                      "
                    >
                      {{
                        users.objectCount.amount_win
                          ? currencyFormat(users.objectCount.amount_win)
                          : 0
                      }}
                    </td>
                  </template>
                </v-data-table>
              </div>
              <!-- <div class="text-center pt-2">
                <v-pagination
                  v-model="dataRequest.page"
                  :length="
                    users
                      ? users.objectCount.count % dataRequest.rowsPerPage != 0
                        ? users.objectCount.count / dataRequest.rowsPerPage + 1
                        : users.objectCount.count / dataRequest.rowsPerPage
                      : 0
                  "
                ></v-pagination>
                <v-text-field
                  :value="dataRequest.rowsPerPage"
                  label="Items per page"
                  type="number"
                  min="-1"
                  max="15"
                  @input="dataRequest.rowsPerPage = parseInt($event, 10)"
                ></v-text-field>
              </div> -->
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <loader
      v-if="this.getLoading == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
  </div>
</template>

<script>
export default {
  props: ["darkmode", "dialog", "users", "dataRequest", "user"],
  data: () => {
    return {
      defaultSelected: null,
      selectChannel: [
        {
          id: 0,
          name: "All",
        },
        {
          id: 1,
          name: "CO1",
        },
        {
          id: 2,
          name: "CO2",
        },
        {
          id: 3,
          name: "CO3",
        },
        {
          id: 4,
          name: "CO4",
        },
        {
          id: 15,
          name: "CO5",
        },
        {
          id: 16,
          name: "CO6",
        },
        {
          id: 5,
          name: "KL1",
        },
        {
          id: 6,
          name: "BA1",
        },
        {
          id: 7,
          name: "BA2",
        },
        {
          id: 8,
          name: "TD1",
        },
        {
          id: 9,
          name: "TD2",
        },
        {
          id: 10,
          name: "LO1",
        },
        {
          id: 11,
          name: "YU1",
        },
        {
          id: 12,
          name: "AP1",
        },
        {
          id: 17,
          name: "AP2",
        },
        {
        id: 13,
        name: "FT1",
        },
        {
          id: 14,
          name: "TS1",
        },
        {
        id: 20,
        name: "HAN2",
        },
        {
          id: 21,
          name: "HAN3",
        },
        {
          id: 90,
          name: "LOTTO99(SLOT)",
        },
        {
          id: 91,
          name: "YUKI(SLOT)",
        },
        {
          id: 92,
          name: "PP78(SLOT)",
        },
        {
          id: 93,
          name: "KLAKLOUK(SLOT)",
        },
        {
          id: 94,
          name: "DRAGON(SLOT)",
        },
        {
          id: 95,
          name: "BACCARAT(SLOT)",
        },
        {
          id: 96,
          name: "DODEN(SLOT)",
        },
        {
          id: 97,
          name: "SICBO(SLOT)",
        },
        {
          id: 98,
          name: "TAIXIU(SLOT)",
        },
      ],
      dialogListUser: false,
      headers: [
        // {
        //   text: "No",
        //   align: "start",
        //   value: "no",
        // },
        { text: "App", value: "app_id" },
        { text: "IP Address", value: "public_ip" },
        { text: "Date", value: "date" },
        { text: "FightNo", value: "fight_no" },
        { text: "Channel Type", value: "channel_type" },
        { text: "BettingType", value: "type_of_betting.name" },
        { text: "Result", value: "result1.name" },
        { text: "Amount", value: "amount" },
        { text: "AmountWin/Lose", value: "amount_win" },
        { text: "Cast", value: "cast" },
      ],
      userChild: [],
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      v_startDate: false,
      v_endDate: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_search: "",
      channel_type: null,
      getLoading: false,
      // result2: {name: "OKK2", desc: "OK2", color: "cir-red"},
      // result3: {name: "OKK3", desc: "OK3", color: "cir-green"},
      // result4: {name: "OKK4", desc: "OK4", color: "cir-yellow"},
    };
  },
  computed: {
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "playerDetail";
        else return null;
      } else return null;
    },
  },
  methods: {
    search() {
      let data = {
        channel_type : this.channel_type,
        v_search: this.v_search
      }
      if (this.user.role_name == "AGENT-RP" &&  this.user.role_name == "SUB-AGENT-RP"){
        this.$emit("getData5", data);
      }else {
        this.$emit("onSearch", data);
      }
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },
    updatePage() {
      this.getLoading = true;
      this.$emit("getData5");
      this.getLoading = false;
    },
  },
};
</script>

<style scoped>
table {
  font-family: "khmer mef1" !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
.amount-bet-color {
  color: goldenrod !important;
}

.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}

.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}

.cir-red {
  color: #ba2d4a;
  font-weight: bold;
}

.cir-blue {
  color: rgb(57, 175, 214);
  font-weight: bold;
}

.cir-green {
  color: green;
}

.cir-cancel {
  color: grey;
}
</style>
